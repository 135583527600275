:root {
  --clr-green-light: #45ae21;
  --clr-grey-light: #d9d9d9;
  --clr-grey-dark: #888888;
  --clr-grey-white: #f5f5f5;
}

* {
  font-family: "Mulish";
  font-size: 22px;
}

h1 {
  font-size: 40px;
  font-weight: normal;
}

button {
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}

.sidebar-btn {
  background-color: #ffffff;
  color: #000000;
  font-weight: normal;
  margin-top: 0.5rem;
  font-size: 18px;
  border: none;
}

.sidebar-btn-selected {
  background-color: #ffffff;
  color: #45ae21;
  font-weight: bold;
  margin-top: 0.5rem;
  font-size: 18px;
  border: none;
}

.popup-close-image {
  height: 40px;
}

.table-header {
  background-color: #f5f5f5 !important;
  border-radius: 0 !important;
  border: 0 !important;
}

.table-header-cell {
  color: #45ae21 !important;
  font-size: 18px !important;
  font-weight: 200 !important;
  border: 0 !important;
}

.table-header-cell-grey {
  color: #888888 !important;
  font-size: 15px !important;
  font-weight: 200 !important;
}

.Mui-selected {
  background-color: #45ae21;
}
